import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";

const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [descriptor, setDescriptor] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [processResult, setProcessResult] = useState("");

  // Dropzone configuration for multiple files
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      }
    },
    multiple: true,
    accept: ".tsv", // Restrict to TSV files
  });

  // Handle file upload
  const handleUpload = async () => {
    if (selectedFiles.length === 0 || !descriptor) {
      alert("Please select at least one file and provide a descriptor.");
      return;
    }

    console.log("Displaying selectedFile object")
    console.log(selectedFiles)
    console.log(typeof selectedFiles);

    // Creating the Formdata object to post to backend
    const formData = new FormData();

    for (const selectedFile of selectedFiles) {
      formData.append('files', selectedFile, selectedFile.name);
    }

    // Parse the descriptor to obtain the database value
    const descriptorParsed = JSON.parse(descriptor);
    formData.append("database", descriptorParsed.database);

    try {
      setUploadStatus("Uploading...");
      const response = await axios.post("http://127.0.0.1:3050/upload", formData);
      const filePaths = response.data.filePaths; 
      setUploadStatus("Upload Successful!");

      // Formats the files list for ingestion API
      const filesList = filePaths.map((path) => ({
        csv_filepath: path,
        delimiter: "\t",
      }));

      if (filePaths.length > 0) {
        await callIngestAPI(filesList, descriptorParsed.database);
      }
    } catch (error) {
      setUploadStatus("Upload Failed. Please try again.");
      console.error("Upload Error:", error);
    }
  };

  const callIngestAPI = async (filesList, database) => {
    // This is currently hardcoded -- should be modified
    const HARDCODED_LOOKUP_TABLE_PATH = "/data1/datatalk_domains/jbook_defense/_lookup_table.csv";

    try {
      const response = await fetch("http://127.0.0.1:3050/ingest", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          files: filesList,
          database,
          lookup_table_path: HARDCODED_LOOKUP_TABLE_PATH,
        }),
      });

      console.log("Logging outputs")
      
      // From here on we get buggy D: 
      const result = await response.json();
      console.log(result)
      const output_message = result.message

      console.log("Outputted message should be:", output_message);
      setProcessResult(output_message);
    } catch (error) {
      console.error("Error in ingestion:", error);
      alert("Error processing files. Check console for details.");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h1>Datatalk: File Upload and Ingestion</h1>

      {/* Drag-and-Drop Area */}
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #007bff",
          padding: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        {selectedFiles.length > 0 ? (
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        ) : (
          <p>Drag and drop files here, or click to select them</p>
        )}
      </div>

      {/* Descriptor Input */}
      <textarea
        placeholder="Enter JSON descriptor here..."
        value={descriptor}
        onChange={(e) => setDescriptor(e.target.value)}
        style={{ width: "100%", marginBottom: "10px", padding: "10px" }}
      />

      {/* Upload Button */}
      <button
        onClick={handleUpload}
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        {uploadStatus || "Upload Files"}
      </button>

      {/* Process Results */}
      {processResult && (
        <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc" }}>
          <h3>Process Results</h3>
          <p>{processResult}</p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
